import "../styles/Articles.css";
import Footer from "../components/Footer";
import Navigation from "../components/Navigation";

function DefaultArticle()
{
    window.scrollTo(0,0);

    const datum = "XX.XX.202X";
    const ArticleTitel = "Dieses ist der Titel des Artikels";
    const ArticleSubTitel = "Dieses ist der Untertitel"


    return(
        <div className="ArticleWrapper">
        <Navigation></Navigation>
         <div className='SiteBackground'></div>
         <div className="ArticleBody">
            <div className="ArticleHeader">
                <div className="ArticleHeaderDatum">{datum}</div>
                <div className="ArticleHeaderTitle">{ArticleTitel}</div>
                <div className="ArticleHeaderSubitel">{ArticleSubTitel}</div>
            </div>
            <div className="ArticleTextBody">
                <div className="ArticleColumn">Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text TextText Text Text Text Text Text Text Text TextText Text Text Text Text Text Text Text TextText Text Text Text Text Text Text Text Text</div>
                <div className="ArticleColumn_PicRight">
                    <div className="PicRightText">Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text TextText Text Text Text Text Text Text Text TextText Text Text Text Text Text Text Text TextText Text Text Text Text Text Text Text Text</div>
                    <div className="PicRightFrame">
                        <div className="PicRight_PICTURE"></div>
                        <div className="PicRight_Subtitle">Abb. 1:</div>
                    </div>
                </div>
            </div>
         </div>
         <Footer></Footer>
        </div>
    )
}

export default DefaultArticle;