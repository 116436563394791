
import Navigation from '../components/Navigation'
import Footer from '../components/Footer'
import '../styles/landingpage.css'
import { Link } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';

function Landingpage()
{
    const windowSize = useRef([window.innerWidth, window.innerHeight]);

    const [landingpageTitel, setLandingPageTitel] = useState('LandingPageTitel');
    const [LandingpageNews, setLandingpageNews] = useState('LandingPageNewsLine');
    const [LandingPageTitelRow1, setLandingPageTitelRow1] = useState('LandingPageTitel_Row1');
    const [LandingPageTitelRow2, setLandingPageTitelRow2] = useState('LandingPageTitel_Row2');
    const [LandingPageNewsElement, setLandingPageNewsElement] = useState('LandingPageNewsElement');
    const [NewsElementDate, setNewsElementDate] = useState('NewsElementDate');
    const [NewsElementTitel, setNewsElementTitel] = useState('NewsElementTitel');
    const [LandingpageBanner, setLandingpageBanner] = useState('LandingpageBanner');

    useEffect(()=>{
        if(windowSize.current[0] < 600)
        {
            setLandingPageTitel('LandingPageTitel_Mobile');
            setLandingpageNews('LandingPageNewsLine_Mobile');
            setLandingPageTitelRow1('LandingPageTitel_Row1_Mobile');
            setLandingPageTitelRow2('LandingPageTitel_Row2_Mobile');
            setLandingPageNewsElement('LandingPageNewsElement_Mobile');
            setNewsElementDate('NewsElementDate_Mobile');
            setNewsElementTitel('NewsElementTitel_Mobile');
            setLandingpageBanner('LandingpageBanner_Mobile')
        }

    }, windowSize.current[0])

    return(
        <div className='LandingpageWrapper'>
            <Navigation></Navigation>
            <div className={LandingpageBanner}></div>
            <div className={landingpageTitel}>
                <div className={LandingPageTitelRow1}>Lesedidaktik und Lesediagnostik</div>
                <div className={LandingPageTitelRow2}>Schreibdidaktik und Schreiben mit KI </div>
            </div>
            <div className={LandingpageNews}>

            <Link to="https://sciendo.com/de/article/10.2478/ristal-2024-0001"><div className={LandingPageNewsElement}>
                    <div className={NewsElementDate}>6.07.2024</div>
                    <div className={NewsElementTitel}>Internationaler Beitrag: Usage and beliefs of student teachers towards AI in writing</div>
            </div></Link>

            <Link to="/news"> <div className={LandingPageNewsElement}>
                    <div className={NewsElementDate}>2.07.2024</div>
                    <div className={NewsElementTitel}>Vorstellung des Leselern-Spiels "Wort-Ag-enten"(Di.ge.LL-Tagung 2024)</div>
                </div>
            </Link>

            <Link to="/news"><div className={LandingPageNewsElement}>
                    <div className={NewsElementDate}>22.06.2024</div>
                    <div className={NewsElementTitel}>Veranstaltung der 1. SDD Postdoc-Akademie</div>
                </div></Link>    
            
                         


               
            </div>
        </div>

    )
}

export default Landingpage