import {Routes} from 'react-router-dom';
import {BrowserRouter} from 'react-router-dom';
import {Route} from 'react-router-dom';

import Landingpage from './pages/landingpage.js';
import Person from './pages/person.js';
import Impressum from './pages/Impressum.js';
import Publikationen from './pages/Publikationen.js';
import News from './pages/News.js';
import Projekte from './pages/Projekte.js';
import DefaultArticle from './articles/DefaultArticle.js'
import Article_Trialog from './articles/Article_Trialog.js';
import Article_AGLesen24 from './articles/Article_AGLesen24.js';

function Main()
{
    return(<>
    <BrowserRouter>
   
            <Routes>
                <Route path="/" element={<Landingpage/>}></Route>
                <Route path="/about" element={<Person/>}></Route>
                <Route path="/impressum" element={<Impressum/>}></Route>
                <Route path="/publikationen" element={<Publikationen/>}></Route>
                <Route path="/projekte" element={<Projekte/>}></Route>
                <Route path="/news" element={<News/>}></Route>
                <Route path="/Artikel_Trialog" element={<Article_Trialog/>}></Route>
                <Route path="/DefaultArticle" element={<DefaultArticle/>}></Route>
            </Routes>          
    </BrowserRouter>
    </>)
}

export default Main;