import '../styles/Navigation.css'
import { Link } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';

function Navigation()
{
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const [ScrollPosition, setScrollPosition] = useState(0);
    const [NavWrapper, setNavWrapper] = useState('NavWrapper');
    const [NavElement, setNavElement] = useState('Nav_NavElement');
    const [LinkColor, setLinkColor] = useState('white');
    const [NavTrenner, setNavTrenner] = useState('Nav_Trenner');
    const [NavHome, setNavHome] = useState('Nav_HomeButton');
    const [MobileNav, setMobileNav] = useState('Mobile_Nav_gone');
    const [MobileNavScreen, setMobileNavScreen] = useState('MobileNavScreen_gone');
    const [MobileMenuActive, setMobileMenuActive] = useState(false);

    
    const scrollingDown = () => {
        
        if(windowSize.current[0] > 600)
        {
            setNavWrapper('NavWrapper_scrolled');
            setNavElement('Nav_NavElement_scrolled');
            setLinkColor('darkgrey');
            setNavTrenner('Nav_Trenner_scrolled');
        }

        if(windowSize.current[0] < 600)
        {
            setNavWrapper('NavWrapper_scrolled_Mobile');
            setMobileNav('Mobile_Nav_active');
            setLinkColor('darkgrey');
            
        }
        
    }

    const scrollingTop = () => {
        setNavWrapper('NavWrapper');
        setLinkColor('white');
        if(windowSize.current[0] > 600)
        {
            setNavWrapper('NavWrapper');
            setNavElement('Nav_NavElement');
            setNavTrenner('Nav_Trenner');
        }

        if(windowSize.current[0] < 600)
        {
            setMobileNav('Mobile_Nav');
        }

    }

    useEffect(()=>{
        if(windowSize.current[0] < 600)
        {
           setNavElement('Nav_NavElement_gone');
           setNavTrenner('Nav_Trenner_gone');
           setNavHome('Nav_HomeButton_Mobile');
           setMobileNav('Mobile_Nav');
        }

    }, windowSize.current[0]);

    const showMobileNavScreen = () =>{
        if(MobileMenuActive == false)
        {
            setMobileNavScreen('MobileNavScreen');
            setMobileNav('Mobile_Nav_active');
            setMobileMenuActive(true);
        }

        else
        {
            setMobileNavScreen('MobileNavScreen_gone');
            setMobileNav('Mobile_Nav');
            setMobileMenuActive(false);
        }

        
    }

    window.onscroll = scrollingDown;

    window.addEventListener("scroll", function(){
        if(window.scrollY==0){
          scrollingTop()          
        } else {
         
        }
      });

    
    

    return(
        <div className={NavWrapper}>

            <div className={NavHome}><Link to="/" style={{color:LinkColor}}>Gerrit Helm</Link></div>
            <div className={NavTrenner}> | </div>
            <div className={NavElement}><Link to="/about"style={{color:LinkColor}}>Zur Person</Link></div>
            <div className={NavElement}><Link to="/publikationen"style={{color:LinkColor}}>Publikationen</Link></div>
            <div className={NavElement}><Link to="/news"style={{color:LinkColor}}>Aktuelles</Link></div>
            <div className={NavElement}><Link to="/projekte"style={{color:LinkColor}}>Projekte</Link></div>
            <div className={NavElement}></div>
            <div className={NavElement}><Link to="/impressum"style={{color:LinkColor}}>Impressum</Link></div>
            <div className={MobileNav} onClick={showMobileNavScreen}>=</div>
            <div className={MobileNavScreen}>
                <div className='MobileNavScreenTitel'>Menü</div>
                <div className='MobileNavScreenElement1'><Link to="/"style={{color:"black"}}>Home</Link></div>
                <div className='MobileNavScreenElement2'><Link to="/about"style={{color:"black"}}>Zur Person</Link></div>
                <div className='MobileNavScreenElement2'><Link to="/publikationen"style={{color:"black"}}>Publikationen</Link></div>
                <div className='MobileNavScreenElement2'><Link to="/news"style={{color:"black"}}>Aktuelles</Link></div>
                <div className='MobileNavScreenElement2'><Link to="/projekte"style={{color:"black"}}>Projekte</Link></div>
                <div className='MobileNavScreenElement2'><Link to="/impressum"style={{color:"black"}}>Impressum</Link></div>
            </div>
        </div>
    )

}
export default Navigation