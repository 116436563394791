import "../styles/Articles.css";
import Footer from "../components/Footer";
import Navigation from "../components/Navigation";
import { useEffect, useRef, useState } from 'react';

function Article_Trialog()
{
    window.scrollTo(0,0);
    const windowSize = useRef([window.innerWidth, window.innerHeight]);

    const datum = "25.01.2024";
    const ArticleTitel = "Trialog Bildung 2035";
    const ArticleSubTitel = "Mitwirkende aus der Schulpraxis, Forschung und Bildungsadministration beraten gemeinsam über Möglichkeiten der Stärkung der Basiskompetenzen (Lesen, Schreiben, Rechnen) durch digitale Tools und Anwendungen.";

    const [ArticleBody, setArticleBody] = useState('ArticleBody');
    const [ArticleColumn_PicLeft, setArticleColumn_PicLeft] = useState('ArticleColumn_PicLeft');
    const [PicLeftText, setPicLeftText] = useState('PicLeftText');
    const [PicLeftFrame, setPicLeftFrame] = useState('PicLeftFrame');
    const [ArticleColumn_PicRight, setArticleColumn_PicRight] = useState('ArticleColumn_PicRight');
    const [PicRightFrame, setPicRightFrame] = useState('PicRightFrame');
    const [PicRightText, setPicRightText] = useState('PicRightText');

    useEffect(()=>{
        if(windowSize.current[0] < 600)
        {
            setArticleBody('ArticleBody_Mobile');
            setArticleColumn_PicLeft('ArticleColumn_PicLeft_Mobile');
            setPicLeftText('PicLeftText_Mobile');
            setPicLeftFrame('PicLeftFrame_Mobile');
            setArticleColumn_PicRight('ArticleColumn_PicRight_Mobile');
            setPicRightFrame('PicRightFrame_Mobile');
            setPicRightText('PicRightText_Mobile');
        }

    }, windowSize.current[0])


    return(
        <div className="ArticleWrapper">
        <Navigation></Navigation>
         <div className='SiteBackground'></div>
         <div className={ArticleBody}>
            <div className="ArticleHeader">
                <div className="ArticleHeaderDatum">{datum}</div>
                <div className="ArticleHeaderTitle">{ArticleTitel}</div>
                <div className="ArticleHeaderSubitel">{ArticleSubTitel}</div>
            </div>
            <div className="ArticleTextBody">
                <div className="ArticleColumn">Mit dem Wunsch, im Jahr 2035 "in allen Kitas und Schulen in Deutschland flächendeckend qualitativ hochwertige, chancengerechte Bildung verwirklicht, den bestmöglichen Bildungserfolg für jede:n garantiert" zu haben, lud die <b>Robert-Bosch-Stiftung</b> Beteiligte aus der Schulpraxis, Wissenschaftler:innen der Universitäten und Mitglieder der Bildungsadministration zum Trialog. Bereits im Herbst 2023 wurden Kleingruppen gebildet, in denen jeweils ein:e Vertreter:in der Hochschulen mit zwei Lehrkräften aus der Schulpraxis in moderierten Workshops zusammenarbeiteten. Ziel war hierbei die Gestaltung einer gemeinsamen Präsentation, die wiederum Perspektiven der Forschung und Schulpraxis zusammenführen sollte.</div>
                <div className={ArticleColumn_PicLeft}>
                <div className={PicLeftFrame}>
                        <div className="Pic_Trialog1"></div>
                        <div className="PicLeft_Subtitle">Abb. 1: Stefan Jänen, Gerrit Helm und Sina Karschöldgen mit Melanie Hörenz</div>
                    </div>
                    <div className={PicLeftText}>Mit großem Vergnügen durfte ich diese Workshops gemeinsam mit Sina Karschöldgen und Stefan Jänen von der Grundschule Bogenstraße in Solingen verbringen. Nach einem kurzen Austausch mit den zwei engagierten Lehrkräften über grundsätzliche Fragen zum Einsatz künstlicher Intelligenz aus Sicht der Forschung, denen Berichte aus der Praxis hinzugefügt wurden, stand für unser Dreierteam sehr früh ein gemeinsames Interesse fest: Wir wollten die äußerlich vielversprechende KI-Anwendung <i>fiete.ai</i>, die verspricht, automatisiert individuelles Textfeedback zu liefern, einerseits theoretisch in den Blick nehmen und andererseits in der Praxis erproben, um die Potenziale, Grenzen und Voraussetzungen der Anwendung berichten zu können. Entwickelt wurde deshalb ein kleines empirisches Setting, in welchem Schüler:innen eine Beschreibung (einer Fantasiemaschine) verfassen, zu dieser ein Feedback von <i>fiete.ai</i> abrufen und die Texte auf Grundlage des Feedbacks eigenständig überarbeiten. Die überarbeiteten Kindertexte zu dieser profilierten Schreibaufgaben (Bachmann / Becker-Mrotzek 2010) sollten dann als Kern der Präsentation dienen. Vorangestellten werden sollte eine kurze theoretische Einordnung der Arbeitsphase der Überarbeitung in den Schreibprozess selbst. Hierbei sollten zugleich aktuelle Erkenntnisse zum Schreiben im Kontext von KI referiert werden. Die Präsentation selbst fand nun am 25. Januar in Berlin in den Räumlichkeiten der Robert-Bosch-Stiftung statt.</div>
                </div>
                <div className="ArticleColumn">Der Präsentationstag startete unter dem Vorzeichen des anhaltenden Streiks der Deutschen Bahn spontan als Online-Veranstaltung, die über das Deutsche Schulportal gestreamt wurde. Nur wenige Beteiligte (darunter wir) fanden sich tatsächlich vor Ort ein. Eröffnet wurde der Tag mit Grußworten von Dr. Dagmar Wolf, der Bereichsleiterin Bildung in der Robert-Bosch-Stiftung und Udo Michallik, dem Generalsekretär der Kultusministerkonferenz (KMK). Diese mahnten, dass das deutsche Bildungssystem keinesfalls "den Anschluss verlieren" dürfe mit Blick auf die rasant fortschreitenden technischen Entwicklungen. Hierzu müsse der Fokus zukünftig verstärkt darauf gelegt werden, die Erkenntisse aus der Forschung für die Praxis nutzbar zu machen. Somit sei der Trialog ein Traumszenario.</div>
                <div className="ArticleColumn">Eine erste Keynote gestaltete <b>Prof. Dr. Susanne Prediger</b> (TU Dortmund) zum Thema <i>Digital gestützte Diagnose und Sicherung von Basiskompetenzen: Was könnten Learning Analytics und KI aus fachdidaktischer Perspektive leisten?</i> Sie stellte zunächst klar, dass ein Weiterlernen nicht funktionieren kann, wenn die Basis fehlt. Das Fach Mathematik sei hier besonders 'gnadenlos'. Computergestützte Anwendungen können hier eine Ressource darstellen, wobei sie betont, dass der Computer die Lehrkräfte nicht ersetzen, aber unterstützen kann. Zumindest wäre dies in der Theorie möglich. Tatsächlich zeige sich aber, dass bestehende digitale Tools und auch KI-Anwendungen weit weg von einer fachdidaktischen Angemessenheit sind. Die meisten Tools funktionieren mit einem <i>Du hast es noch nicht richtig gemacht, mache es bitte nochmal</i>-Prinzip. Erklärungen, die den aktuellen Stand der fachdidaktischen Forschung erkennen lassen, bleiben in digitalen Tools aus. So führe die Digitalisierung an einigen Stellen zu einem fachdidaktischen Rückschritt und nicht Fortschritt, sodass "Eltern viel Geld für schlechte Apps aus[geben]". Eine Lösung sieht Prediger vor allem in aus öffentlicher Hand finanzierten digitalen Tools, bei denen eine Überprüfung erfolgt, ob diese den Standards gerecht werden. Denn eigentlich böten digitale und gerade KI-gestützte Tools großes Potenzial für den schulischen Einsatz gerade im Fach Mathematik. Hier stellte sie vor allem die automatische Überprüfung von Aufgaben und das direkte Feedback als Chancen heraus. Ihr Fazit lautete somit: Es gibt große Verheißungen, was digitale Technik kann, doch sind diese längst nicht erfüllt. Eventuell liefern KI-Anwendung hier zukünftig den notwendig Schritt nach vorn. Final müssen diese sich jedoch daran messen lassen, wie gut sie Lehrkräfte im Alltag unterstützen.</div>
                
                <div className={ArticleColumn_PicRight}>
                    <div className={PicRightText}>Eine zweite Keynote lieferte <b>Nicole Wrana</b>, Schulleiterin der am Projekt beteiligten Grundschule Bogenstraße in Solingen. Sie berichtete aus der Praxis, dass Lehrkräfte sich immer stärker belastet fühlen, aber der Output leider nicht gleichzeit mit ansteigt. Somit wird ein Austausch und die Suche nach umsetzbaren Lösungen eine täglich dringendere Frage. Zum Ersten, so stellt sie heraus, gilt zu klären, WAS GENAU Basiskompetenzen im Zeitalter der Digitalisierung noch seien. Sie illustriert dies am Beispiel der Arbeit mit dem Wörterbuch, bei dem bereits innerhalb des Kollegiums der Schule zwei Meinungen zu finden seien: Für einige sei es eine durch google, Wikipedia und duden.de heute überflüssige Beschäftigung, für andere gerade deshalb unabdingbar. Eventuell brauche es also eine Neuformulierung der Basiskompetenzen, die so ausgerichtet ist, dass diese eng an die Lebenswirklichkeit der Schüler:innen anknüpft und nicht mehr nur das abbilde, was Lehrer:innen bereits in ihrer Schulzeit gelernt und erfahren haben. Eine neue (und eventuell vergessene) Grundlage müssen dabei die emotionalen Basiskompetenzen bilden. Für Nicole Wrana steht die Schule damit vor grundsätzlichen Transformationsprozessen, da das, was aktuell in Schulen vermittelt werde, nicht länger zukunftsträchtig sei.</div>
                    <div className={PicRightFrame}>
                        <div className="Pic_Trialog2"></div>
                        <div className="PicRight_Subtitle">Abb. 2: Schulleiterin Nicole Wrana</div>
                    </div>
                </div>
                <div className="ArticleColumn">Eine dritte Keynote hielt <b>Dr. Günter Klein</b>, Direktor am Institut für Bildungsanalysen Baden-Württemberg, zum Thema <i>Digitale Tools im Unterricht: Vom Zielbild zur Umsetzung - was es braucht und woran zu denken ist.</i> Er verdeutlichte, was die Sicherung der Basiskompetenzen auf den drei Ebenen i) Unterricht, ii) Schule und iii) System bedeutet. Im Zentrum stehe hierbei im Moment der Einsatz digitaler Anwendungen als Werkzeuge. Insbesondere der Begriff 'Werkzeug' greife ihm jedoch zu kurz: Er werde dem Paradigma und der Kultur der Digitalität nicht gerecht. In Deutschland jedoch flächendeckend eine Kultur der Digitalität zu erreichen, müsse das oberste Ziel sein.</div>
                <div className={ArticleColumn_PicLeft}>
                <div className={PicLeftFrame}>
                        <div className="Pic_Trialog3"></div>
                        <div className="PicLeft_Subtitle">Abb. 3: Live-Stream mit Prof. Dr. Tobias Dörfler</div>
                    </div>
                    <div className={PicLeftText}>Nach einer Mittagspause starteten die Präsentationen der Trialog-Workshops des Winters. Wir machten den Auftakt gefolgt von der Gruppe aus <b>Sabrina Nolte</b> und <b>Sonja Westebbe</b> der Klinikschule Paul-Maar in Bonn und <b>Prof. Dr. phil. Dipl.-Psych. Tobias Dörfler</b> (PH Heidelberg). Die zwei Lehrkräfte stellten das Diagnosetool <i>Online Diagnose</i> vom Westermann Verlag vor und berichteten dessen potenzielle Einsatzmöglichkeiten in der Schulpraxis. Sie berichteten, dass der Einsatz des Tools eine enorme Erleichterung und Hilfe für Lehrkräfte sei, diesen aber nach wie vor noch relevante Aufgaben zukommen. Dies illustrierten sie durch Einblicke in ihren Schulalltag.<p>Tobias Dörfler präsentierte daraufhin die Plattform <i>Starke BASIS Deutsch</i>, die als (Austausch-)Plattform für Lehrkräfte konzipiert ist. Hier sollen Lehrkräfte beispielsweise lernen, digitale Tools selbst einschätzen zu können. Wichtige Feature dieser (und jeder Plattform) seien für ihn eine psychologische Fundierung, didaktische Bewährung und praktische Umsetzbarkeit der Inhalte.</p></div>
                </div>
                <div className="ArticleColumn">Vor der Präsentation des dritten Trialog-Workshops wurde der Abiturient <b>Florian Fabricius</b>, Vorsitzender der Bundesschülervertretung, dazugeschaltet. Er trug - in m.E. augenöffnender Weise - die aktuelle Sichtweise der Schüler:innen vor: So merken auch Schüler:innen, dass Lehrkräfte theoretisch mit KI-Tools viel Zeit gewinnen könnten. Diese freigewordene Zeit könnte dann für eine vertiefte Unterrichtsplanung genutzt werden und dies sei in Schüler:innenaugen auch dringend nötig: Denn es bestehe das Gefühl, dass sie zwar nun mit neuesten technischen Geräten ausgestattet seien, jedoch an keiner Stelle unterwiesen werden, wie sie nun mit diesen umgehen: "Medienkompetenz entsteht nicht von selbst". Es war ein für mich [G.H.] beeindruckender Auftritt, bei dem Worte, die sonst v.a. bei Axel Krommer oder Philippe Wampfler zu lesen sind, nun aus der Mitte der Schüler:innen kamen. </div>
                <div className="ArticleColumn">Den zweiten Teil des Trialog-Tages bildeten <b>Meet-the-Experts-Sitzungen</b>, bei denen die zuvor im Stream-Zuschauenden nun in Zoom-Räumen mit den Mitwirkenden in wechselnden Settings sprechen und individuelle Fragen stellen konnten. Diskutiert wurden die grundsätzlichen Potenziale von KI-Anwendungen für die Schule. Betont wurde dabei, dass der Einsatz von KI-Tools die bereits vorherrschende (Bildungs-)Ungerechtigkeit nicht noch mehr vergrößern dürfe. <p>In einer zweiten Runde präsentierte u.a. ein Team aus <b>Jana Aguilar Marquez</b> (Klett), <b>Laura Tuschen</b> (Frauenhofer Institut Oldenburg) und <b>Georg Kraus</b> (FRÖBEL e.V.) ein KI-gestütztes Sprach-Screening, das in (in Alltagssituationen erfassten) Sprachaufnahmen von unter-3-Jährigen problematische Muster erkennen kann. So soll eine Art 'Frühwarnsystem' entwickelt werden, sodass eventuell gefährdete Kinder frühzeitig eine systematische Sprachdiagnose durchlaufen, um dann schnell in ihren sprachlichen Fertigkeiten und Fähigkeiten gefördert werden zu können.</p> </div>
                <div className={ArticleColumn_PicLeft}>
                <div className={PicLeftFrame}>
                        <div className="Pic_Trialog4"></div>
                        <div className="PicLeft_Subtitle">Abb. 4: Das 'Live'-Studio des Trialogs in Berlin</div>
                    </div>
                    <div className={PicLeftText}><b>Fazit:</b><p>Der Trialog Bildung 2035 hat gezeigt und bewiesen, dass der vielerorts tot-erklärte Transfer und der Austausch zwischen Theorie und Praxis durchaus gelingen kann, wenn man nur einmal in die Kommunikation einsteigt. Am Ende war es dann kaum überraschend, dass die Positionen, Ziele und Probleme der Bildungsadministration, der fachdidaktischen Forschung, der Schulpraxis und sogar der Schüler:innen selbst sehr nahe beieinander lagen und eine enorme Schnittmenge zeigten. Rasant entwickelten die Vorträge und Workshop-Präsentationen eine Verweisstruktur, bei der Präsentierende anhaltend auf Formulierung wie <i>wie XYZ uns bereits vorgestellt hat,...</i> zurückgreifen konnten. Werden die unterschiedlichen Mitwirkenden also an einen Tisch geholt, so fällt der Dialog, Trialog oder 'Mulit-log' leicht. Interessant wird nun, ob es bei diesem einmaligen gegenseitigen Zustimmungsaustausch bleibt oder ob diesem weitere folgen dürfen und Implikationen in Forschung und Praxis sichtbar werden. Dies bleibt spannend!</p></div>
                </div>
                <div className="ArticleColumn"></div>
            </div>
         </div>
         <Footer></Footer>
        </div>
    )
}

export default Article_Trialog;