import Navigation from '../components/Navigation';
import '../styles/Person.css';
import Footer from '../components/Footer';
import { useEffect, useRef, useState } from 'react';


function Person()
{
    window.scrollTo(0,0);
    const windowSize = useRef([window.innerWidth, window.innerHeight]);

    const [PersonIdCard, setPersonIdCard] = useState('PersonIdCard');
    const [PersonBody, setPersonBody] = useState('PersonBody');
    const [PersonIdCardTextHD, setPersonIdCardTextHD] = useState('PersonIdCardTextHD');
    const [PersonIdCardTextLine, setPersonIdCardTextLine] = useState('PersonIdCardTextLine');
    const [PersonIdCardText, setPersonIdCardText] = useState('PersonIdCardText');
    const [PersonIdCardPic, setPersonIdCardPic] = useState('PersonIdCardPic');
    const [JobsCardTitel, setJobsCardTitel] = useState('JobsCardTitel');
    const [JobsCordRowYear, setJobsCordRowYear] = useState('JobsCordRowYear');
    const [JobsCordRowPlace, setJobsCordRowPlace] = useState('JobsCordRowPlace');
    const [JobsCordRowJob, setJobsCordRowJob] = useState('JobsCordRowJob');
    const [SchoolPlace, setSchoolPlace] = useState('SchoolPlace');
    const [SchoolYear, setSchoolYear] = useState('SchoolYear');
    const [SchoolGoalTitel, setSchoolGoalTitel] = useState('SchoolGoalTitel');
    const [SchoolNote, setSchoolNote] = useState('SchoolNote');
    const [Erg1, setErg1] = useState('');
    const [Erg2, setErg2] = useState('');
    const [Erg3, setErg3] = useState('');

    useEffect(()=>{
        if(windowSize.current[0] < 600)
        {
           setPersonIdCard('PersonIdCard_Mobile');
           setPersonBody('PersonBody_Mobile');
           setPersonIdCardTextHD('PersonIdCardTextHD_Mobile');
           setPersonIdCardTextLine('PersonIdCardTextLine_Mobile');
           setPersonIdCardText('PersonIdCardText_Mobile');
           setPersonIdCardPic('PersonIdCardPic_Mobile');
           setJobsCardTitel('JobsCardTitel_Mobile');
           setJobsCordRowYear('JobsCordRowYear_Mobile');
           setJobsCordRowPlace('JobsCordRowPlace_Mobile');
           setJobsCordRowJob('JobsCordRowJob_Mobile');
           setSchoolYear('SchoolYear_Mobile');
           setSchoolPlace('SchoolPlace_Mobile');
           setSchoolGoalTitel('SchoolGoalTitel_Mobile');
           setSchoolNote('SchoolNote_Mobile')
           setErg1('an der FSU Jena.');
           setErg2('an der Bergischen Universität Wuppertal.');
           setErg3('an der Uni Oldenburg.');
        }

    }, windowSize.current[0]);



    return(
    <div className='PersonWrapper'>
        <Navigation></Navigation>
        <div className='SiteBackground'></div>
        <div className={PersonBody}>
            <div className={PersonIdCard}>
                <div className= {PersonIdCardPic}></div>
                <div className={PersonIdCardText}>
                    <div className={PersonIdCardTextHD}>Gerrit Helm</div>
                    <div className={PersonIdCardTextLine}> Sprachdidaktische Modellierung des Lesens</div>
                    <div className={PersonIdCardTextLine}> Lesediagnostik in der Primarstufe</div>
                    <div className={PersonIdCardTextLine}> Schreiben im Kontext von KI</div>
                    <div className={PersonIdCardTextLine}> (Ästhetisches) Lernen in Bilderbuch-Apps</div>
                </div>
            </div>
            <div className='JobsCard'>
                <div className={JobsCardTitel}>Beruflicher Werdegang:</div>
                <div className='JobsCardJobArea'>
                    <div className='JobsCardTrenner'></div>

                    <div className='JobsCardRow'>
                        <div className={JobsCordRowYear}>seit 2024</div>
                        <div className={JobsCordRowPlace}>FSU Jena</div>
                        <div className={JobsCordRowJob}>Wissenschaftlicher Mitarbeiter im Projekt 'Schreiben mit KI' {Erg1}</div>
                    </div>

                    <div className='JobsCardTrenner'></div>

                    <div className='JobsCardRow'>
                        <div className={JobsCordRowYear}>WiSe '23 - SoSe '24</div>
                        <div className={JobsCordRowPlace}>Uni Wuppertal</div>
                        <div className={JobsCordRowJob}>Mitarbeiter im drittmittelgeförderten Projekt 'Leseband_NRW' {Erg2}</div>
                    </div>

                    <div className='JobsCardTrenner'></div>

                    <div className='JobsCardRow'>
                        <div className={JobsCordRowYear}>SoSe '20 - WiSe '23</div>
                        <div className={JobsCordRowPlace}>Uni Wuppertal</div>
                        <div className={JobsCordRowJob}>Wissenschaftlicher Mitarbeiter am Lehrstuhl für Lese- und Literaturdidaktik {Erg2}</div>
                    </div>

                    <div className='JobsCardTrenner'></div>

                    <div className='JobsCardRow'>
                        <div className={JobsCordRowYear}>SoSe '17 - WiSe '20</div>
                        <div className={JobsCordRowPlace}>Uni Oldenburg</div>
                        <div className={JobsCordRowJob}>Wissenschaftlicher Mitarbeiter der Sprachdidaktik und Mitarbeiter im GHR300 (Praxisphase) {Erg3}</div>
                    </div>

                     <div className='JobsCardTrenner'></div>

                    <div className='JobsCardRow'>
                        <div className={JobsCordRowYear}>WiSe 16/17</div>
                        <div className={JobsCordRowPlace}>Uni Oldenburg</div>
                        <div className={JobsCordRowJob}>Lehrauftrag für English Linguistics {Erg3}</div>
                    </div>

                    <div className='JobsCardTrenner'></div>

                    <div className='JobsCardRow'>
                        <div className={JobsCordRowYear}>bis SoSe '16</div>
                        <div className={JobsCordRowPlace}>Uni Oldenburg</div>
                        <div className={JobsCordRowJob}>Tätigkeiten als Hilfskraft und Tutor in der Sprach- und Literaturdidaktik sowie der Linguistik {Erg3}</div>
                    </div>

                    <div className='JobsCardTrenner'></div>

                </div>
            </div>

            <div className='JobsCard'>
            <div className={JobsCardTitel}>Ausbildung:</div>
                <div className='JobsCardJobArea'>
                <div className='JobsCardTrenner'></div>
                <div className='JobsCardRow'>
                        <div className={SchoolYear}>01.10.2017 - 11.12.2023</div>
                        <div className='SchoolGoal'>
                            <div className={SchoolGoalTitel}>Promotion</div>
                            <div className={SchoolNote}>"Über die Entwicklung eines (digitalen) Lesediagnose-Verfahrens zur Sichtbarmachung von Unterschieden in den hierarchieniedrigsten Lesefertigkeiten".</div>
                            <div className={SchoolNote}>Betreuung: Prof. Dr. Nanna Fuhrhop</div>
                        </div>
                        <div className={SchoolPlace}>Uni Oldenburg</div>
                </div>
                        
                <div className='JobsCardTrenner'></div>
                <div className='JobsCardRow'>
                        <div className={SchoolYear}>01.04.2015 - 30.11.2016</div>
                        <div className='SchoolGoal'>
                            <div className={SchoolGoalTitel}>Masterstudium</div>
                            <div className={SchoolNote}>Master of Education für das Lehramt an Gymnasien (Deutsch / Englisch).</div>
                        </div>
                        <div className={SchoolPlace}>Uni Oldenburg</div>
                </div>
                <div className='JobsCardTrenner_Blank'></div>
                <div className='JobsCardRow'>
                        <div className={SchoolYear}>01.10.2012 - 30.11.2016</div>
                        <div className='SchoolGoal'>
                            <div className={SchoolGoalTitel}>Masterstudium</div>
                            <div className={SchoolNote}>Master of Education für das Lehramt an Grund-, Haupt- und Realschulen (Deutsch / Englisch).</div>
                        </div>
                        <div className={SchoolPlace}>Uni Oldenburg</div>
                </div>
                <div className='JobsCardTrenner'></div>

                <div className='JobsCardRow'>
                        <div className={SchoolYear}>01.10.2009 - 30.09.2012</div>
                        <div className='SchoolGoal'>
                            <div className={SchoolGoalTitel}>Bachelorstudium</div>
                            <div className={SchoolNote}>Bachelor of Arts (Germanistik / Anglistik).</div>
                        </div>
                        <div className={SchoolPlace}>Uni Oldenburg</div>
                </div>
                <div className='JobsCardTrenner'></div>

                <div className='JobsCardRow'>
                        <div className={SchoolYear}>01.10.2009 - 30.09.2012</div>
                        <div className='SchoolGoal'>
                            <div className={SchoolGoalTitel}>Abitur</div>
                            <div className={SchoolNote}>Leistungskurse: Englisch, Musik, musik. Komposition.</div>
                        </div>
                        <div className={SchoolPlace}>Städt. Gymnasium Petershagen</div>
                </div>
                <div className='JobsCardTrenner'></div>

                </div>
            </div>

        </div>
        <Footer></Footer>

    </div>
        )
}

export default Person